<template>
  <div class="chart-warning-container">
    <div class="chart-content">
      <Row>
        <Col span="8">&nbsp;</Col>
        <Col span="8">
          <Date-picker @on-change="changeTime" type="daterange" placeholder="选择日期"></Date-picker>
        </Col>
        <Col span="8">&nbsp;</Col>
      </Row>
      <div class="chart" ref="warningData"></div>
    </div>
    <Row :gutter="20">
      <Col span="12">
        <div class="unusual-box">
          <Row :gutter="32">
            <Col span="11" style="text-align: right;">
              <img src="@/assets/img/icon_warning_big.png" alt="">
            </Col>
            <Col span="13" style="text-align: left;">
              <p class="unusual-num">{{count}}</p>
              <p>异常预警数量</p>
            </Col>
          </Row>
        </div>
      </Col>
      <Col span="12">
        <div class="status-box">
          <div class="chart-status" ref="warningStatus"></div>
        </div>
      </Col>
    </Row>
  </div>
</template>

<script type="text/ecmascript-6">
import echarts from 'echarts';
import chartService from '@/services/chartService';
export default {
	data() {
		return {
			searchForm: {
				start_time: '',
				end_time: '',
			},
			lineChart: '',
			pieChart: '',
			xArr: [],
			yArr: [],
			count: 0,
			pieData: [],
		};
	},
	mounted() {
		// echarts实例化
		this.lineChart = echarts.init(this.$refs.warningData);
		this.pieChart = echarts.init(this.$refs.warningStatus);
		// 初始化实例
		let lineOptions = {
			title: {
				text: '预警数据统计',
				textStyle: {
					color: '#424e67',
					fontWeight: 'normal',
					fontSize: 14,
				},
				left: '45%',
				bottom: 40,
			},
			grid: {
				show: true,
				borderColor: '#e3e3e3',
				bottom: 80,
			},
			color: ['#f18985'],
			xAxis: {
				type: 'category',
				boundaryGap: false,
				axisLine: { onZero: false },
				data: [],
			},
			yAxis: [
				{
					name: '次',
					type: 'value',
				},
			],
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'cross',
					animation: false,
					label: {
						backgroundColor: '#505765',
					},
				},
			},
			dataZoom: [
				{
					show: true,
				},
				{
					type: 'inside',
				},
			],
			series: [
				{
					name: '预警次数',
					type: 'line',
					symbolSize: 10,
					animation: false,
					symbol: 'circle',
					areaStyle: {
						normal: {
							color: 'transparent',
						},
					},
					lineStyle: {
						normal: {
							width: 3,
							color: '#f18985',
						},
					},
					data: [],
				},
			],
		};
		let pieOptions = {
			tooltip: {
				trigger: 'item',
				formatter: '{b}: {c} ({d}%)',
			},
			title: {
				text: '目前处理',
				textStyle: {
					color: '#fdad00',
					fontWeight: 'normal',
					fontSize: 28,
				},
				subtext: '状态',
				subtextStyle: {
					color: '#999',
					fontWeight: 'normal',
					fontSize: 18,
				},
				left: '49%',
				top: '40%',
				textAlign: 'center',
			},
			legend: {
				orient: 'vertical',
				right: 20,
				data: ['已处理', '未处理', '处理中'],
			},
			color: ['#f1ce7e', '#66b0ea', '#f18985'],
			series: [
				{
					type: 'pie',
					clockwise: false,
					startAngle: 60,
					radius: ['50%', '75%'],
					label: {
						normal: {
							show: true,
							position: 'inside',
							formatter: '{d}%',
						},
					},
					data: [
						{ value: 0, name: '未处理' },
						{ value: 0, name: '处理中' },
						{ value: 0, name: '已处理' },
					],
				},
			],
		};
		this.lineChart.setOption(lineOptions);
		this.lineChart.showLoading();
		this.pieChart.setOption(pieOptions);
		this.pieChart.showLoading();
		this.getChartWarning();
	},
	methods: {
		getChartWarning() {
			chartService.getChartWarning(this.searchForm).then((data) => {
				this.count = data.total_count[0] ? data.total_count[0].count : 0;
				this.xArr = [];
				this.yArr = [];
				data.data_list.forEach((item) => {
					this.xArr.push(item.info_time);
					this.yArr.push(item.count);
				});
				this.lineChart.hideLoading();
				this.lineChart.setOption({
					xAxis: {
						data: this.xArr,
					},
					series: [
						{
							// 根据名字对应到相应的系列
							name: '预警次数',
							data: this.yArr,
						},
					],
				});
				this.pieData = [];
				data.status_list.forEach((item) => {
					this.pieData.push({
						value: item.count,
						name: item.status,
					});
				});
				if (this.pieData.length === 0) {
					this.pieData = [
						{ value: 0, name: '未处理' },
						{ value: 0, name: '处理中' },
						{ value: 0, name: '已处理' },
					];
				}
				this.pieChart.hideLoading();
				this.pieChart.setOption({
					series: [
						{
							data: this.pieData,
						},
					],
				});
			});
		},
		changeTime(value) {
			this.searchForm.start_time = value[0];
			this.searchForm.end_time = value[1];
			this.getChartWarning();
		},
	},
};
</script>

<style lang="css" scoped>
.chart-warning-container .unusual-box {
  padding: 125px 0;
  background-color: #87d4e3;
  color: #fff;
  font-size: 24px;
  line-height: 50px;
}
.chart-warning-container .unusual-box p {
  width: 150px;
  text-align: center;
}
.chart-warning-container .unusual-box .unusual-num {
  font-size: 40px;
  line-height: 40px;
}
.chart-warning-container .status-box {
  height: 340px;
  background-color: #fff;
}
.chart-warning-container .chart-status {
  width: 95%;
  height: 340px;
}
</style>
